<style lang="scss" scoped>
  .tolttip-message{
    display:none !important;
  }
</style>

<style lang="scss">
body {
  background: #fff !important;
}


</style>


<style lang="scss">

.card-body{
  padding: 1.5rem !important;
}
#header{
  position: relative!important;
}
.btn-comercio {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-comercio:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}


.title__login {
  color: #21458d;
  font-size: 30px;
  font-weight: 600;
}

.btn__ingresar {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 45px;
  font-weight: 500 !important;
  font-size: 18px !important;
  letter-spacing: 1px;
}

.avatar__alicia {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 220px;
}

.container__login {
  margin-top: 2%;
  // height: 85vh;
}
.label__input {
  font-size: 17px;
  font-weight: 500;
  color: #000;
}

.p__input {
  margin-bottom: 0px;
  position: relative;
  top: -10px;
  font-size: 13px;
  color: #4d4d4d;
}

////////////////////////////////@at-root

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 860px) {
  .avatar__alicia {
    position: relative;
    bottom: 0;
    right: -26%;
    width: 100%;
    max-width: 145px;
    margin: 0 auto;
  }
    
}
</style>


<template>
  <div>
    <header id="header">
      <a href="#hero-section">
        <img src="../../assets/img/logo_negro.png" id="header-img" />
      </a>
      <nav>
        <ul class="mb-0">
          <router-link class="nav-link btn__landing" to="/">
            <img src="../../assets/img/arrow.png" style="width: 45px" />
          </router-link>
        </ul>
      </nav>
    </header>

    <div class="d-flex align-items-center container__login">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      ></loading>
      <CContainer fluid>
        <CRow class="justify-content-center">
          <CCol md="4">
            <CCardGroup>
              <CCard class="mx-md-4 mb-0">
                <CCardBody class="p-4" style="border: 1px solid #b7b7b7">
                  <CForm>
                    <h1 class="title__login">Recuperar tu contraseña</h1>
                    <p
                      v-if="isEmail"
                      style="
                        color: red;
                        font-size: 0.8em;
                        margin: 0 0 5px 0;
                        padding: 0;
                      "
                    >
                      (*)El campo correo está vacio
                    </p>

                    <label class="label__input">Ingresa tu correo electrónico y sigue las instrucciones que se envian por correo.</label>
                    <CInput
                      placeholder="Correo"
                      class
                      type="email"
                      autocomplete="Ingresa tu correo"
                      v-model="email"
                      maxlength="30"
                    >
                    </CInput>
                    <CRow>
                      <CCol col="12" class="text-left">
                        <button
                          type="button"
                          class="btn-comercio btn__ingresar"
                          @click="sendEmail()"
                        >
                          Enviar
                        </button>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
             
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>

    <img class="avatar__alicia" src="../../assets/img/alicia_3.jpg" />

  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";


export default {
  name: "Login",
  data() {
    return {
      email: "",
      code: "",
      isEmail: false,
      isLoading: false,
      fullPage: true,
    };
  },
  components: {
    Loading,
  },
  methods: {
    sendEmail() {
      let vm = this;
      let flagEmail = false;

      if (vm.email == "") {
        vm.isEmail = true;
      } else {
        vm.isEmail = false;
        flagEmail = true;
      }

      if (flagEmail == true) {
        let data = {
          email: vm.email
        };
        console.log("Data =>",data);
        axios
          .post(
            `${process.env.VUE_APP_ROOT_API}/recuperar-contrasena/email`,
            data
          )
          .then(function (res) {
            console.log("Res =>", res);
            vm.isLoading = false;
            if (res.data.data.code == 1) {
              vm.$fire({
                title: "Felicitaciones",
                text: "Se envió el correo exitosamente.",
                type: "success",
                timer: 3000,
              }).then((r) => {
                vm.$router.push("/login");
              });
            } else {
              vm.$fire({
                title: "Seguridad",
                text: res.data.data.message,
                type: "info",
                timer: 3000,
              }).then((r) => {});
            }
          }).catch(error => {
          vm.isLoading = false;
            vm.$fire({
              title: "Error",
              text: "Ocurrió un error en el servidor",
              type: "error",
              timer: 4000
            }).then(r => {
            });
        });
      }
    },
    redirectLanding() {
      let vm = this;
      vm.$router.push("/");
    },
  },
  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    if(localStorage.getItem(`${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`) == "1"){
      vm.$router.push("/dashboard");
    }

    $("#pgb-container").hide();
  },
};
</script>
